import axios from "axios";

let BASEURL = "http://localhost:8000";

if (process.env.NODE_ENV === "production") {
  BASEURL = "https://pyapis.rightfullabs.com";
}

export const baseURL = BASEURL;
export const serverCallAxiosInstance = axios.create({
  timeout: 120000,
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});
