import React from 'react'
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Lottie from 'react-lottie';
import axios from "axios";

import imageclickjson from './lottiejson/imageclickjson.json'
import imageuploadjson from './lottiejson/imageuploadjson.json'


const ImageClickOptions = {
  loop: true,
  autoplay: true, 
  animationData: imageclickjson,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

const ImageUploadOptions = {
  loop: true,
  autoplay: true, 
  animationData: imageuploadjson,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

class Cam extends React.Component {
  constructor() {
    super();
    this.fileInput1 = React.createRef();
    this.fileInput2 = React.createRef();
    // this.cameraNumber = 0;

    this.state = {
      // clickFlag: false,
      // imageDataURL: null,
    };
  }

  // initializeMedia = async () => {
  //   this.setState({ imageDataURL: null });

  //   if (!("mediaDevices" in navigator)) {
  //     navigator.mediaDevices = {};
  //   }

  //   if (!("getUserMedia" in navigator.mediaDevices)) {
  //     navigator.mediaDevices.getUserMedia = function (constraints) {
  //       var getUserMedia =
  //         navigator.webkitGetUserMedia || navigator.mozGetUserMedia;

  //       if (!getUserMedia) {
  //         return Promise.reject(new Error("getUserMedia Not Implemented"));
  //       }

  //       return new Promise((resolve, reject) => {
  //         getUserMedia.call(navigator, constraints, resolve, reject);
  //       });
  //     };
  //   }

  //   //Get the details of video inputs of the device
  //   const videoInputs = await this.getListOfVideoInputs();

  //   //The device has a camera
  //   if (videoInputs.length) {
  //     navigator.mediaDevices
  //       .getUserMedia({
  //         video: {
  //           deviceId: {
  //             exact: videoInputs[this.cameraNumber].deviceId,
  //           },
  //         },
  //       })
  //       .then((stream) => {
  //         this.player.srcObject = stream;
  //       })
  //       .catch((error) => {
  //         console.error(error);
  //       });
  //   } else {
  //     alert("The device does not have a camera");
  //   }
  // };

  // capturePicture = () => {
  //   var canvas = document.createElement("canvas");
  //   canvas.width = this.player.videoWidth;
  //   canvas.height = this.player.videoHeight;
  //   var contex = canvas.getContext("2d");
  //   contex.drawImage(this.player, 0, 0, canvas.width, canvas.height);
  //   this.player.srcObject.getVideoTracks().forEach((track) => {
  //     track.stop();
  //   });

  //   console.log(canvas.toDataURL());
  //   this.setState({ imageDataURL: canvas.toDataURL() });
  // };

  // switchCamera = async () => {
  //   const listOfVideoInputs = await this.getListOfVideoInputs();

  //   // The device has more than one camera
  //   if (listOfVideoInputs.length > 1) {
  //     if (this.player.srcObject) {
  //       this.player.srcObject.getVideoTracks().forEach((track) => {
  //         track.stop();
  //       });
  //     }

  //     // switch to second camera
  //     if (this.cameraNumber === 0) {
  //       this.cameraNumber = 1;
  //     }
  //     // switch to first camera
  //     else if (this.cameraNumber === 1) {
  //       this.cameraNumber = 0;
  //     }

  //     // Restart based on camera input
  //     this.initializeMedia();
  //   } else if (listOfVideoInputs.length === 1) {
  //     alert("The device has only one camera");
  //   } else {
  //     alert("The device does not have a camera");
  //   }
  // };

  // getListOfVideoInputs = async () => {
  //   // Get the details of audio and video output of the device
  //   const enumerateDevices = await navigator.mediaDevices.enumerateDevices();

  //   //Filter video outputs (for devices with multiple cameras)
  //   return enumerateDevices.filter((device) => device.kind === "videoinput");
  // };

  // stopVideo = async () => {
  //   // Get the details of audio and video output of the device
  //   const enumerateDevices = await navigator.mediaDevices.enumerateDevices();
  //   enumerateDevices.stop()
  //   //Filter video outputs (for devices with multiple cameras)
  // };
  
  // handleClickFlag=() => {
  //   this.setState({clickFlag: !this.state.clickFlag})
  // }

  //  dd = async (a) => {
  //     const formD = new FormData()
  //     formD.append('secret_token', 'py');
  //     formD.append('file', a[0]  )
      
  //     const r = await axios.post("http://54.39.50.96/api/mailimprove/ocr",formD,{
  //       headers: {
  //         'content-type': 'multipart/form-data'
  //       },
  //     });
  //   // this.props.setTT([...this.props.tt, (r.data.data).toString()])
  //   }



  onFileUpload = (e) => {
    e.preventDefault()
    // let file_reader = new FileReader()
    let file = e.target.files
    this.props.setImages([...this.props.images, ...file])
    // file_reader.onload = () => {
    //   this.props.setImages([...this.props.images, file_reader.result ])
    // }
    // file_reader.readAsDataURL(file)
    // this.dd(file)

    // this.props.setImages([...this.props.images, ...file])
  }

  render() {
    // const playerORImage = Boolean(this.state.imageDataURL) ? (
    //   <img src={this.state.imageDataURL} alt="cameraPic" />
    // ) : (
    //   <video
    //     ref={(refrence) => {
    //       this.player = refrence;
    //     }}
    //     autoPlay
    //   ></video>
    // );

    return (
      <>
      {/* {this.state.clickFlag &&<>
         <Box
         sx={{
           width: 380,
          //  height: 300,
          }}
          >
        {playerORImage}
        </Box>
        <IconButton onClick={this.capturePicture}><TripOriginIcon fontSize="large" /></IconButton>
        <IconButton onClick={this.switchCamera}><CameraswitchIcon fontSize="large" /></IconButton>
        </>
        } */}

        <Stack
          sx={{ p: 2, pt: 4  }}
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={1}
          >

          <div sx={{ cursor: 'pointer' }} onClick={()=>this.fileInput1.current.click()}>
        <Lottie options={ImageUploadOptions}
          height={80}
          width={80}
          />
          <input 
            ref={this.fileInput1}
            type="file"
            accept="image/*"
            multiple
            style={{ display: 'none' }}
            onChange={this.onFileUpload}
          />
          </div>
          {/* <div sx={{ cursor: 'pointer' }} onClick={()=>{this.handleClickFlag(); this.initializeMedia();}}> */}
          <div sx={{ cursor: 'pointer' }} onClick={()=>this.fileInput2.current.click()}>
        <Lottie options={ImageClickOptions}
          height={80}
          width={80}
          />
          <input 
            ref={this.fileInput2}
            type="file"
            accept="image/*"
            capture="environment"
            style={{ display: 'none' }}
            onChange={this.onFileUpload}
          />
          </div>
      </Stack>


        <Box
          // sx={{
          //   display: 'flex',
          //   flexWrap: 'wrap',
          //   '& > :not(style)': {
          //     m: 1,
          //     width: 80,
          //     height: 80,
          //   },
          // }}
        >
        {this.props.images?.map((image, i) =>
          <Box key={i}>
            {image.name}
            {/* <img src={printFile(image)} width="100%" height="100%" alt='img' /> */}
          </Box>
            )}
        </Box>
      </>
    );
  }
}


export default Cam
