import {useState} from 'react'
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import LoadingButton from '@mui/lab/LoadingButton';
import CardActions from '@mui/material/CardActions';
import LoginIcon from '@mui/icons-material/Login';
import Stack from '@mui/material/Stack';
import Lottie from 'react-lottie';
import TextField from '@mui/material/TextField';
import TextInputField from "./TextInputField";

import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";

import loginJson from './lottiejson/loginjson.json'

const Auth = ({state, handleLogin}) => {
    const { submitLoginLoading } = state

    const { handleSubmit, control, reset } = useForm({
      defaultValues: {
        checkbox: false,
      }
    });


    const [stateAuth, setStateAuth] = useState({
      username: "",
      password: ""
    })
    const {username, password} = stateAuth

    const handleInputOnChange=(e) => {
      setStateAuth({...stateAuth ,[e.target.name]: e.target.value})
    }

    const defaultOptions = {
      loop: true,
      autoplay: true, 
      animationData: loginJson,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };

  return (
    <>
    <CardHeader
          action={
              <Lottie options={defaultOptions}
                height={100}
                width={100}
            />
          }
          title="Login"
          subheader="please enter your credentials"
        />
        <CardContent>
        <Stack spacing={2}>
        {/* <Controller
            name="username"
            control={control}
            rules={{ required: true }}
            render={({ field }) => <TextField autoComplete='off' fullWidth autoFocus variant="outlined" type="text" error={false} required helperText="please enter username" placeholder="please enter registered username" label="Username" {...field} multiline maxRows={4} />}
          /> */}
           <TextInputField autoFocus={true} name="username" value={username} type="text" error={false} required={true} multiline={false} helperTxt="please enter username" placeholderTxt="please enter registered username" label="Username" handleInputOnChange={handleInputOnChange} />
           <TextInputField name="password" value={password} type="password" required={true} multiline={false} helperTxt="please enter password" placeholderTxt="please enter correct password" label="Password" handleInputOnChange={handleInputOnChange} />
        <CardActions>
            <LoadingButton
                sx={{ marginLeft: 'auto', px:4}}
                // disabled={valid}
                loading={submitLoginLoading}
                loadingPosition="start"
                startIcon={<LoginIcon />}
                variant="outlined"
                onClick={()=>handleLogin({'username': username, 'password': password})}
            >
                Submit
            </LoadingButton>
            </CardActions>
            </Stack>
        </CardContent>
    </> 
  )
}

export default Auth