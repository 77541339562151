import React, {useState, useEffect} from 'react';
import Stack from '@mui/material/Stack';
import Card from "@mui/material/Card";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Moment from "moment";
// import PerfectScrollbar from 'react-perfect-scrollbar'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';

import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
import 'react-perfect-scrollbar/dist/css/styles.css';

import { ScrollToTop, CardReader, Auth } from './components';
import {serverCallAxiosInstance} from './service/serverCallAxiosInstance'

import { styled } from '@mui/material/styles';

const WallPaper = styled('div')({
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  overflow: 'hidden',
  background: 'linear-gradient(rgb(255, 38, 142) 0%, rgb(255, 105, 79) 100%)',
  transition: 'all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s',
  '&:before': {
    content: '""',
    width: '140%',
    height: '140%',
    position: 'absolute',
    top: '-40%',
    right: '-50%',
    background:
      'radial-gradient(at center center, rgb(62, 79, 249) 0%, rgba(62, 79, 249, 0) 64%)',
  },
  '&:after': {
    content: '""',
    width: '140%',
    height: '140%',
    position: 'absolute',
    bottom: '-50%',
    left: '-30%',
    background:
      'radial-gradient(at center center, rgb(247, 237, 225) 0%, rgba(247, 237, 225, 0) 70%)',
    transform: 'rotate(30deg)',
  },
});

const Widget = styled('div')(({ theme }) => ({
  // padding: 1,
  borderRadius: 16,
  // width: 343,
  maxWidth: '100%',
  margin: 'auto',
  position: 'relative',
  zIndex: 1,
  // backgroundColor:
  //   theme.palette.mode === 'dark' ? 'rgba(0,0,0,0.6)' : 'rgba(255,255,255,0.4)',
  // backdropFilter: 'blur(40px)',
}))

function Copyright() {
  return (
    <>
    <Typography variant="body2" sx={{ fontWeight: 'bold' }} color="text.secondary" align="center">
      Visiting Card Reader
    </Typography>
    <Typography variant="caption" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://www.rightfullabs.com" target="_blank">
        Rightfullabs
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
    </>
  );
}

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const App = () => {
  const currentDateV = (Moment().format( "dddd h:mma D MMM YYYY" )).toString();
  const LSR = localStorage.getItem('LSR')

  let InitData = {
    token: "",
    username: "",
    currentDate: currentDateV,
    openBackdrop: false,
    submitLoginLoading: false,
    loggedIn: false,
    submitCardRLoading: false,
  }

  const LSChanges = () => {
    let obj = JSON.parse(LSR)
    obj.currentDate = currentDateV
    return obj
  }

  const initialState = LSR?{...InitData, ...LSChanges()}:InitData
  const [state, setState] = useState(initialState)
  const [images, setImages] = useState([])
  const [listFlag, setListFlag] = useState(false)
  const [dataGetListLoading, setDataGetListLoading] = useState(false)
    const [dataGetList, setDataGetList] = useState({
    count: 0,
    data: []
  })
  const [stateSnackbarAlert, setStateSnackbarAlert] = useState({open: false, msg: "", severity: ""})

  const handleSetState=(argState) => {
    const data = {...state, ...argState}
    setState(data)
    localStorage.setItem('LSR', JSON.stringify(data))
    console.log('dsdsd', data)
  }

  const handleLogin = async (body) => {
    handleSetState({ submitLoginLoading: true })
    console.log(body)

    try {
      const r = await serverCallAxiosInstance.post("/api/officetools/visitingcardreader/login",body);
      const rdata=r.data
      if("message" in rdata) {
        if (rdata.message === "success"){
          const {username, token} = rdata.data
          handleSetState({loggedIn: true, submitLoginLoading: false, token: token, username: username})
          handleSnackbarAlertClick("Login Success", "success")
        } else if (rdata.message === "error"){
          handleSnackbarAlertClick(rdata.err_message, "error")
          handleSetState({ submitLoginLoading: false })
        } else {
          handleSnackbarAlertClick("Not acceptable", "warning")
          handleSetState({ submitLoginLoading: false })
        }
      } else {
        handleSnackbarAlertClick("Please try again", "warning")
        handleSetState({ submitLoginLoading: false })
      }
    } catch (error) {
      handleSnackbarAlertClick("Not acceptable", "warning")
      handleSetState({ submitLoginLoading: false })
    }
  }


  const handleLogout = async (body) => {
    try {
      const r = await serverCallAxiosInstance.post("/api/officetools/visitingcardreader/logout", body);
      const rdata=r.data
      if (rdata.message === "success"){
        handleSnackbarAlertClick("Logout Success", "success")
      } 
    } catch (error) {
      console.log(error)
    }
    
    localStorage.clear()
    setState(InitData)
  }

  const handleSubmitCardReaderApiCall= async (body) => {
    handleSetState({ submitCardRLoading: true })

    try {
      const formData = new FormData()
      formData.append('username', state.username);
      formData.append('token', state.token);
      // formData.append('list_file', body.images);

      // body.images?.map(image=>{
      //   console.log(image, image.name)
      // })
      images?.map(image=>{
        formData.append('list_file', image  )
      })

      // tt?.map(tt_=>{
      // formData.append('list_file_text', tt_);
      // })

      formData.append('title', body.title);
      formData.append('remarks', body.remarks);
      formData.append('team', body.team);

      const r = await serverCallAxiosInstance.post("/api/officetools/visitingcardreader/data-upload-proccess",formData,{
        headers: {
          'content-type': 'multipart/form-data'
        },
      });
      const rdata=r.data
      if (rdata.message === "success"){
        handleSnackbarAlertClick("Submit is successful", "success")
        setListFlag(true)
        setImages([])
      } else if (rdata.message === "error"){
        handleSnackbarAlertClick(rdata.err_message, "error")
      } else {
        handleSnackbarAlertClick("Please try again", "warning")
      }
      handleSetState({ submitCardRLoading: false })
    } catch (error) {
      handleSetState({ submitCardRLoading: false })
      handleSnackbarAlertClick("Please try again", "warning")
    }
  }

  const handleDataUploadGetList = async () => {
    setDataGetListLoading(true)
    
    try {
      const r = await serverCallAxiosInstance.post("/api/officetools/visitingcardreader/data-upload-get-list",{"username": state.username, "token": state.token});
      const rdata=r.data

      if("message" in rdata) {
        if (rdata.message === "success"){
          setDataGetList({ count: rdata.count, data: rdata.data })
          // handleSnackbarAlertClick("Getting List load success", "info")
        } else if (rdata.message === "error"){
            handleSnackbarAlertClick("Getting List load error", "error")
        } else {
          console.log('else')
        }
      } else {
        handleSnackbarAlertClick("Try again", "error")
      }
      
    } catch (error) {
      console.log(error)
    }

    setDataGetListLoading(false)
  }
  

  useEffect( async () => {
    if(state.token){
      handleSetState({ openBackdrop: true })

    try {
      const body = {"username": state.username, "token": state.token};
      const r = await serverCallAxiosInstance.post("/api/officetools/visitingcardreader/get-login-info", body);
      const rdata=r.data
      if (rdata.message === "success"){
        const {username, token} = rdata.data
        handleSetState({loggedIn: true, token: token, username: username})
        handleSnackbarAlertClick("get info success", "info")

      } else if (rdata.message === "error"){
        handleSnackbarAlertClick(rdata.err_message, "error")
        setTimeout(() => {
          handleSetState({ loggedIn: false })
          localStorage.clear()
        }, 3000);
      } else {
        console.log('else')
      }
    } catch (error) {
      console.log(error)
    }

    handleSetState({ openBackdrop: false })

    } else {
      handleSetState({ loggedIn: false })
      localStorage.clear()
    }
  }, [])


  function TransitionUp(props) {
    return <Slide {...props} direction="up" />;
  }
  
  const [transition, setTransition] = React.useState(undefined);

  const handleSnackbarAlertClick = (arg1, arg2) => {
    setTransition(() => TransitionUp);
    setStateSnackbarAlert({"open": true, "msg": arg1, "severity": arg2});
  };
  
  const handleSnackbarAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
  
    setStateSnackbarAlert({"open": false, "msg": "", "severity": ""});
  };

  useEffect(()=>{
    if(state.token && listFlag){
      handleDataUploadGetList()
    }
  }, [state.token,listFlag])


  return (
    <>
      <ThemeConfig>
        <ScrollToTop />
        <GlobalStyles />

        <Stack
          sx={{ width: '100%', height: '100%', p: 1  }}
          // sx={{ width: '100%', height: '100%', p: 1, background: 'linear-gradient(45deg, #FF8E53 30%, #FE6B8B 90%)'  }}
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={1}
          >
          <Widget>
          <>
          <Card sx={{ width: '100%'}}>
          <>
              {state.loggedIn ?
                <CardReader state={state} images={images} setImages={setImages} handleLogout={handleLogout} handleSubmitCardReaderApiCall={handleSubmitCardReaderApiCall} dataGetList={dataGetList} dataGetListLoading={dataGetListLoading} listFlag={listFlag} setListFlag={setListFlag} />
                :
                <Auth state={state} handleLogin={handleLogin} />
              }
        </>
          </Card>
        
          <Box sx={{ pt: 1, textAlign: 'center'}}>
            <Copyright/>
          </Box>
          
          </>
        </Widget>
        </Stack>

        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={state.openBackdrop}
          >
          <CircularProgress color="inherit" />
        </Backdrop>

        <Snackbar TransitionComponent={transition} key={transition ? transition.name : ''} open={stateSnackbarAlert.open} autoHideDuration={4000} onClose={handleSnackbarAlertClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
          <Alert onClose={handleSnackbarAlertClose} severity={stateSnackbarAlert.severity} sx={{ width: 'auto' }} >
            {stateSnackbarAlert.msg}
          </Alert>
        </Snackbar>
      </ThemeConfig>

      <WallPaper />
    </>
  );
}

export default App;
