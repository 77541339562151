import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {baseURL} from '../service/serverCallAxiosInstance'


const CoverImage = styled('div')({
  width: 100,
  height: 100,
  objectFit: 'cover',
  overflow: 'hidden',
  flexShrink: 0,
  borderRadius: 8,
  backgroundColor: 'rgba(0,0,0,0.08)',
  '& > img': {
    width: '100%',
  },
});

export default function DataList({dataGetList}) {
  const theme = useTheme();
  const {count, data} = dataGetList

  return (
      <>
    
      <Typography sx={{pb:2}} variant="body1" component="div">
        Total count is <b>{count}</b>
      </Typography>
        {data?.map(item =>(
        <Box sx={{ p:1, display: 'flex', alignItems: 'center' }}>
          <CoverImage>
            <img
              alt="image"
              src={`${baseURL}${item.list_file[0].vcf_file}`}
            />
          </CoverImage>
          <Box sx={{ ml: 1.5, minWidth: 0 }}>
            <Typography variant="body2" color="text.secondary" fontWeight={500}>
              {item.title}
            </Typography>
            <Typography variant="caption" color="text.secondary" fontWeight={500}>
              {item.team}
            </Typography>
            <Typography noWrap>
              {item.remarks}
            </Typography>
          </Box>
        </Box>
        ))}

        </>
  );
}
