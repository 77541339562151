import {useState} from 'react'
import Grid from "@mui/material/Grid";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import LoadingButton from '@mui/lab/LoadingButton';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import { red } from "@mui/material/colors";
import Badge from '@mui/material/Badge';
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import ListAltIcon from '@mui/icons-material/ListAlt';
import CircularProgress from '@mui/material/CircularProgress';
import CameraIcon from '@mui/icons-material/Camera';
import PerfectScrollbar from 'react-perfect-scrollbar'
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import TextInputField from "./TextInputField";
import Cam from "./Cam";
import DataList from "./DataList";
import TabsMenu from "./TabsMenu";


const CardReader = ({ state, handleLogout, handleSubmitCardReaderApiCall, dataGetList, dataGetListLoading, listFlag, setListFlag, images, setImages }) => {
  const { currentDate, username, submitCardRLoading } = state;
  
  const [valid, setValid] = useState(false)

    const [stateCardR, setStateCardR] = useState({
      title: "",
      remarks: ""
    })
    const [team, setTeam] = useState(0)
    const {title, remarks} = stateCardR

    const handleInputOnChange=(e) => {
      setStateCardR({...stateCardR ,[e.target.name]: e.target.value})
    }

  return (
    <>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
            {username.charAt(0)}
          </Avatar>
        }
        action={<>
          <IconButton onClick={()=>setListFlag(!listFlag)} size="large">
          {listFlag? <CameraIcon /> :
            <Badge badgeContent={dataGetList.count} color="primary">
              <ListAltIcon />
              {dataGetListLoading &&
              <CircularProgress
                size={24}
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px',
                }}
                />}
              </Badge>
          }
          </IconButton>
          <IconButton onClick={handleLogout} size="large">
            <PowerSettingsNewIcon />
          </IconButton>
          </>
        }
        title={username}
        subheader={currentDate}
      />
      <PerfectScrollbar>
      <Box style={{maxHeight: 480}}>
        <>
      {listFlag?

      <CardContent>
          <DataList dataGetList={dataGetList} />
      </CardContent>

      :
      <CardContent>
        <Stack spacing={2}>

              <Cam images={images} setImages={setImages}/>

              <TabsMenu team={team} setTeam={setTeam} />

                <TextInputField
                  name="title"
                  value={title}
                  required={true}
                  multiline={false}
                  helperTxt="please enter title"
                  focusedTxt="please enter related title related card"
                  placeholderTxt="Title"
                  handleInputOnChange={handleInputOnChange}
                />
                <TextInputField
                  name="remarks"
                  value={remarks}
                  required={false}
                  multiline={true}
                  helperTxt="please enter remarks"
                  focusedTxt="please enter any remarks (not mandatory)"
                  placeholderTxt="Remarks"
                  handleInputOnChange={handleInputOnChange}
                />
        </Stack>
        <CardActions sx={{ justifyContent: 'center' }}>
          <LoadingButton
            sx={{ px:4 }}
            loading={submitCardRLoading}
            loadingPosition="start"
            startIcon={<CheckCircleIcon />}
            variant="outlined"
            // disabled={images && title && remarks && team}
            onClick={()=>handleSubmitCardReaderApiCall({
              title: title,
              remarks: remarks,
              team: team === 1? 'AM': team === 2? 'BD' : 'All Teams'
            })}
            >
            Submit
        </LoadingButton>
        </CardActions>

      </CardContent>
      }
      </>
      </Box>
      </PerfectScrollbar>
    </>
  );
};

export default CardReader;
