import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: 'primary.light',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'primary',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'red',
    },
    '&:hover fieldset': {
      borderColor: 'warning',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'primary.lighter',
    },
  },
});

export default function TextInputField({type, name, label, required, multiline, helperTxt, error, placeholderTxt, handleInputOnChange, autoFocus=false}) {

  return (
    <Box>
        <CssTextField autoComplete='off' fullWidth name={name} label={label} error={error} required={required} type={type} helperText={helperTxt} placeholder={placeholderTxt} multiline={multiline} maxRows={4} onChange={handleInputOnChange} autoFocus={autoFocus} />
    </Box>
  );
}
